<template>
    <div class="h-full pb-10">
        <div class="flex gap-2 items-center">
            <h3 class="text-xl font-bold">{{$route.name}}</h3>
        </div>

        <div class="content bg-white shadow rounded mt-2 p-3 h-full">
            <a-spin :spinning="isLoading" style="display:block; margin: 0 auto" />

            <a-tabs v-model:activeKey="activeKey2">
                <template #tabBarExtraContent>
                    <a-button @click="CheckAllOrderQueues">Get Queue Status</a-button>
                </template>
                <a-tab-pane key="Unfulfillable" tab="Unfulfillable">
                    <DxDataGrid :data-source="orders" :remote-operations="false" :allow-column-reordering="true" :row-alternation-enabled="true" :show-borders="true" :allow-column-resizing="true">
                        <DxFilterRow :visible="true" />
                        <DxMasterDetail :enabled="true" template="masterDetailTemplate" />
                        <DxSearchPanel :visible="false" :highlight-case-sensitive="true" />
                        <DxPaging :page-size="30" />
                        <DxPager :visible="true" :allowed-page-sizes="pageSizes" :display-mode="displayMode" :show-page-size-selector="showPageSizeSelector" :show-info="showInfo" :show-navigation-buttons="showNavButtons" />
                        <DxColumn data-field="order_detail.NumOrderId" caption="ID" :width="70" />
                        <DxColumn data-field="order_detail.GeneralInfo.SecondaryReference" caption="BC ID" />
                        <DxColumn data-field="order_detail.CustomerInfo.ChannelBuyerName" caption="Name" />
                        <DxColumn data-field="order_detail.CustomerInfo.Address.EmailAddress" caption="Email" />
                        <DxColumn data-field="missing_items_count" caption="Item Count" width="100" />
                        <DxColumn data-field="order_detail.GeneralInfo.ReceivedDate" caption="Date" data-type="datetime" format="dd-MM-y H:mm" sort-order="desc" />
                        <DxColumn data-field="priority" caption="Priority" width="80" cell-template="checkboxTemplate" />
                        <DxColumn data-field="q_status" caption="Queue Status" />
                        <DxColumn data-field="email_was_sent" caption="Email Sent" width="100" />
                        <DxColumn data-field="contacted_other_method" caption="Contacted via Other Method" width="100" cell-template="emailCheckboxTemplate" />

                        <DxColumn :width="200" :allow-sorting="false" cell-template="cellTemplate" />

                        <template #checkboxTemplate="{ data }">
                            <a-checkbox :checked="data.data.priority" @change="UpdatePriority(data.data, $event)"></a-checkbox>
                        </template>

                        <template #emailCheckboxTemplate="{ data }">
                            <a-checkbox :checked="data.data.contacted_other_method" @change="UpdateContactedOtherMethod(data.data, $event)"></a-checkbox>
                        </template>
                        <template #cellTemplate="{ data }">
                            <div class="flex items-center gap-5">
                                <a-dropdown :trigger="['click']">
                                    <a-button style="margin-left: 8px"> Select Option...
                                        <DownOutlined />
                                    </a-button>
                                    <template #overlay>
                                        <a-menu>
                                            <a-menu-item key="0" @click="AddToKue(data.data)">Add to Queue</a-menu-item>
                                            <a-menu-item key="1" @click="PreviewEmail(data.data, 'darkside')" v-if="data.data.order_detail.CustomerInfo.Address.EmailAddress && data.data.email_sent == 0">Send Email</a-menu-item>
                                            <a-menu-item key="1" @click="PreviewEmail(data.data, 'darkside')" v-if="data.data.order_detail.CustomerInfo.Address.EmailAddress && data.data.email_sent >= 1">Send Another Email</a-menu-item>
                                            <a-menu-item key="2" @click="PreviewEmail(data.data, 'fabrication')" v-if="data.data.order_detail.CustomerInfo.Address.EmailAddress && data.data.email_sent == 0">Send Fab Email</a-menu-item>
                                            <a-menu-item key="2" @click="PreviewEmail(data.data, 'fabrication')" v-if="data.data.order_detail.CustomerInfo.Address.EmailAddress && data.data.email_sent >= 1">Send Another Fab Email</a-menu-item>
                                            <a-menu-item key="3" @click="PreviewEmail(data.data, 'clutchstop')" v-if="data.data.order_detail.CustomerInfo.Address.EmailAddress && data.data.email_sent == 0">Send Clutchstop Email</a-menu-item>
                                            <a-menu-item key="3" @click="PreviewEmail(data.data, 'clutchstop')" v-if="data.data.order_detail.CustomerInfo.Address.EmailAddress && data.data.email_sent >= 1">Send Another Clutchstop Email</a-menu-item>
                                            <a-menu-divider />
                                            <a-menu-item key="4" @click="DeleteOrder(data.data.order_id)">Delete</a-menu-item>
                                        </a-menu>
                                    </template>
                                </a-dropdown>
                            </div>
                        </template>
                        <template #masterDetailTemplate="{ data }">
                            <div class="overflow-x-auto">
                                <table class="w-full border">
                                    <thead>
                                    <tr>
                                        <th class="bg-gray-100 border-b border-r px-2 py-2">SKU</th>
                                        <th class="bg-gray-100 border-b border-r px-2 py-2">Title</th>
                                        <th class="bg-gray-100 border-b border-r px-2 py-2">Qty</th>
                                        <th class="bg-gray-100 border-b border-r px-2 py-2">Stock Level</th>
                                        <th class="bg-gray-100 border-b border-r px-2 py-2">Delivered</th>
                                        <th class="bg-gray-100 border-b border-r px-2 py-2">Outstanding</th>
                                        <th class="bg-gray-100 border-b border-r px-2 py-2">Lead Time</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(item, index) in data.data.missing_items" :key="index" v-show="item.type == 'Missing'">
                                        <td class="bg-white border-r px-2 py-2" :class="{'border-b border-gray-200': index !== data.data.missing_items.length - 1}">{{item.SKU}}</td>
                                        <td class="bg-white border-r px-2 py-2" :class="{'border-b border-gray-200': index !== data.data.missing_items.length - 1}">{{item.ItemTitle}}</td>
                                        <td class="bg-white border-r px-2 py-2" :class="{'border-b border-gray-200': index !== data.data.missing_items.length - 1}">{{item.Qty}}</td>
                                        <td class="bg-white border-r px-2 py-2" :class="{'border-b border-gray-200': index !== data.data.missing_items.length - 1}">
                                            <template v-if="item.StockLevels">
                                                {{item.StockLevels.StockLevel}}
                                            </template>
                                            <template v-else>0</template>
                                        </td>
                                        <td class="bg-white border-r px-2 py-2" :class="{'border-b border-gray-200': index !== data.data.missing_items.length - 1}">{{item.Delivered}}</td>
                                        <td class="bg-white border-r px-2 py-2" :class="{'border-b border-gray-200': index !== data.data.missing_items.length - 1}">{{item.Outstanding}}</td>
                                        <td class="bg-white px-2 py-2" :class="{'border-b border-gray-200': index !== data.data.missing_items.length - 1}">{{item.LeadTime}}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </template>
                    </DxDataGrid>
                </a-tab-pane>
                <a-tab-pane key="All" tab="All">
                    <DxDataGrid :data-source="allOrders" :remote-operations="false" :allow-column-reordering="true" :row-alternation-enabled="true" :show-borders="true" :allow-column-resizing="true">
                        <DxFilterRow :visible="true" />
                        <DxMasterDetail :enabled="true" template="masterDetailTemplate" />
                        <DxSearchPanel :visible="false" :highlight-case-sensitive="true" />
                        <DxPaging :page-size="30" />
                        <DxPager :visible="true" :allowed-page-sizes="pageSizes" :display-mode="displayMode" :show-page-size-selector="showPageSizeSelector" :show-info="showInfo" :show-navigation-buttons="showNavButtons" />
                        <DxColumn data-field="NumOrderId" caption="ID" :width="70" />
                        <DxColumn data-field="GeneralInfo.SecondaryReference" caption="BC ID" />
                        <DxColumn data-field="CustomerInfo.ChannelBuyerName" caption="Name" />
                        <DxColumn data-field="CustomerInfo.Address.EmailAddress" caption="Email" />
                        <DxColumn data-field="item_count" caption="Item Count" width="100" />
                        <DxColumn data-field="GeneralInfo.ReceivedDate" caption="Date" data-type="datetime" format="dd-MM-y H:mm" sort-order="desc" />
                        <DxColumn data-field="q_status" caption="Queue Status" />
                        <DxColumn data-field="email_was_sent" caption="Email Sent" width="100" />
                        <DxColumn :width="200" :allow-sorting="false" cell-template="cellTemplate" />
                        <template #cellTemplate="{ data }">
                            <div class="flex items-center gap-5" v-if="data.data.CustomerInfo.Address.EmailAddress">
                                <a-dropdown :trigger="['click']">
                                    <a-button style="margin-left: 8px"> Select Option...
                                        <DownOutlined />
                                    </a-button>
                                    <template #overlay>
                                        <a-menu>
                                            <a-menu-item key="1" @click="PreviewEmail(data.data, 'darkside')" v-if="(data.data.email_sent == 0 || !data.data.email_sent)">Send Email</a-menu-item>
                                            <a-menu-item key="1" @click="PreviewEmail(data.data, 'darkside')" v-if="data.data.email_sent >= 1">Send Another Email</a-menu-item>
                                            <a-menu-item key="2" @click="PreviewEmail(data.data, 'fabrication')" v-if="(data.data.email_sent == 0 || !data.data.email_sent)">Send Fab Email</a-menu-item>
                                            <a-menu-item key="2" @click="PreviewEmail(data.data, 'fabrication')" v-if="data.data.email_sent >= 1">Send Another Fab Email</a-menu-item>
                                            <a-menu-item key="3" @click="PreviewEmail(data.data, 'clutchstop')" v-if="(data.data.email_sent == 0 || !data.data.email_sent)">Send Clutchstop Email</a-menu-item>
                                            <a-menu-item key="3" @click="PreviewEmail(data.data, 'clutchstop')" v-if="data.data.email_sent >= 1">Send Another Clutchstop Email</a-menu-item>
                                        </a-menu>
                                    </template>
                                </a-dropdown>
                            </div>
                        </template>
                        <template #masterDetailTemplate="{ data }">
                            <div class="overflow-x-auto">
                                <table class="w-full border">
                                    <thead>
                                    <tr>
                                        <th class="bg-gray-100 border-b border-r px-2 py-2">SKU</th>
                                        <th class="bg-gray-100 border-b border-r px-2 py-2">Title</th>
                                        <th class="bg-gray-100 border-b border-r px-2 py-2">Qty</th>
                                        <th class="bg-gray-100 border-b border-r px-2 py-2">Stock Level</th>
                                        <th class="bg-gray-100 border-b border-r px-2 py-2">Delivered</th>
                                        <th class="bg-gray-100 border-b border-r px-2 py-2">Outstanding</th>
                                        <th class="bg-gray-100 border-b border-r px-2 py-2">Lead Time</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(item, index) in data.data.Items" :key="index">
                                        <td class="bg-white border-r px-2 py-2" :class="{'border-b border-gray-200': index !== data.data.Items.length - 1}">{{item.SKU}}</td>
                                        <td class="bg-white border-r px-2 py-2" :class="{'border-b border-gray-200': index !== data.data.Items.length - 1}">{{item.ItemTitle}}</td>
                                        <td class="bg-white border-r px-2 py-2" :class="{'border-b border-gray-200': index !== data.data.Items.length - 1}">{{item.Qty}}</td>
                                        <td class="bg-white border-r px-2 py-2" :class="{'border-b border-gray-200': index !== data.data.Items.length - 1}">
                                            <template v-if="item.StockLevels">
                                                {{item.StockLevels.StockLevel}}
                                            </template>
                                            <template v-else>0</template>
                                        </td>
                                        <td class="bg-white border-r px-2 py-2" :class="{'border-b border-gray-200': index !== data.data.Items.length - 1}">{{item.Delivered}}</td>
                                        <td class="bg-white border-r px-2 py-2" :class="{'border-b border-gray-200': index !== data.data.Items.length - 1}">{{item.Outstanding}}</td>
                                        <td class="bg-white px-2 py-2" :class="{'border-b border-gray-200': index !== data.data.Items.length - 1}">{{item.LeadTime}}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </template>
                    </DxDataGrid>
                </a-tab-pane>
            </a-tabs>

            <a-modal v-model:visible="emailModalVisible" title="Email" width="80%" @cancel="ResetModal" @ok="SendEmail" :confirm-loading="modalData.loading">
                <div class="flex gap-3">
                    <div class="border-r border-gray-200 flex-1 pr-4 overflow-y-auto" v-if="modalData.data" style="min-width: 500px; max-height:1000px">
                        <a-radio-group v-model:value="selectedTypes" style="margin-bottom: 10px">
                            <a-radio value="all">
                                All
                            </a-radio>
                            <a-radio value="missing">
                                Missing
                            </a-radio>
                            <a-radio value="parent">
                                Parents
                            </a-radio>
                            <a-radio value="composite">
                                Composites
                            </a-radio>
                        </a-radio-group>
                        <a-collapse v-model:activeKey="activeKey">
                            <a-collapse-panel v-for="(item) in modalData.data.missing_items" :key="item.RowId" :header="item.SKU" v-show="ShouldShowItem(item)">
                                <div class="flex items-center mb-1"><span class="font-semibold w-24">SKU:</span>
                                    <a-input size="small" v-model:value="item.SKU" @blur="UpdateEmailPreview"></a-input>
                                </div>
                                <div class="flex items-center mb-1"><span class="font-semibold w-24">Title:</span>
                                    <a-input size="small" v-model:value="item.ItemTitle" @blur="updateItemTitle(item)"></a-input>
                                </div>
                                <div class="flex gap-2 items-center relative"><span class="font-semibold w-24">Lead Time:</span>
                                    <a-input size="small" v-model:value="item.LeadTime" @blur="UpdateEmailPreview" class="block"></a-input>

                                    <button @click="item.ShowCalendar = true">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" class="pointer-events-none"><path fill="none" d="M0 0h24v24H0z"/><path d="M17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1h2v2h6V1h2v2zm3 8H4v8h16v-8zm-5-6H9v2H7V5H4v4h16V5h-3v2h-2V5zm-9 8h2v2H6v-2zm5 0h2v2h-2v-2zm5 0h2v2h-2v-2z"/></svg>
                                    </button>

                                    <div v-clickoutside="(e) => OnClickOutside(e, item)">
                                        <div class="absolute top-10 left-16 z-50 bg-white" :style="{ width: '300px', border: '1px solid #d9d9d9', borderRadius: '4px' }" style="box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 10%);" v-show="item.ShowCalendar">
                                            <a-calendar :fullscreen="false" @change="UpdateLeadTime($event, item)"/>
                                        </div>
                                    </div>
                                </div>

                                <template #extra>
                                    <div @click="e => e.stopPropagation()">
                                        <a-checkbox v-model:checked="item.include" @change="UpdateEmailPreview"></a-checkbox>
                                    </div>
                                </template>
                            </a-collapse-panel>
                        </a-collapse>
                        <div class="flex gap-2 mt-2">
                            <a-input-search placeholder="New item..." size="small" @search="AddNewMissingItem" v-model:value="newItemInput">
                                <template #enterButton>
                                    <a-button>Add</a-button>
                                </template>
                            </a-input-search>
                        </div>
                    </div>
                    <div v-html="modalData.emailPreview" style="overflow: auto;"></div>
                </div>
            </a-modal>
        </div>
    </div>
</template>

<script>
import {defineComponent} from "vue";
import {DownOutlined} from '@ant-design/icons-vue';
import clickoutside from '../lib/clickOutside'
import {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxPager,
    DxSearchPanel,
    DxFilterRow,
    DxMasterDetail,
} from 'devextreme-vue/data-grid';
import moment from 'moment';
import doT from 'dot';
export default defineComponent({
    name: "Unfulfillable Orders",
    components: {
        DxDataGrid,
        DxColumn,
        DxSearchPanel,
        DxFilterRow,
        DxPaging,
        DxMasterDetail,
        DxPager,
        DownOutlined
    },
    data() {
        const now = moment();
        return {
            apiUrl: "https://api.darkside-developments.com",
            isLoading: false,
            pageSizes: [30, 60, 'all'],
            displayModes: [{
                text: 'Display Mode \'full\'',
                value: 'full'
            }, {
                text: 'Display Mode \'compact\'',
                value: 'compact'
            }],
            displayMode: 'full',
            showPageSizeSelector: true,
            showInfo: true,
            showNavButtons: true,
            selectedTypes: 'missing',
            columns: [
                {
                    title: 'ID',
                    dataIndex: 'order_detail.NumOrderId',
                    key: 'order_detail.NumOrderId',
                    sorter: (a, b) => a.order_detail.NumOrderId - b.order_detail.NumOrderId,
                },
                {
                    title: 'BC ID',
                    dataIndex: 'order_detail.GeneralInfo.SecondaryReference',
                    key: 'order_detail.GeneralInfo.SecondaryReference',
                    sorter: (a, b) => parseInt(a.order_detail.GeneralInfo.SecondaryReference.replace('-', '')) - parseInt(b.order_detail.GeneralInfo.SecondaryReference.replace('-', '')),
                },
                {
                    title: 'Name',
                    dataIndex: 'order_detail.CustomerInfo.ChannelBuyerName',
                    key: 'order_detail.CustomerInfo.ChannelBuyerName',
                    sorter: (a, b) => a.order_detail.CustomerInfo.ChannelBuyerName.localeCompare(b.order_detail.CustomerInfo.ChannelBuyerName),
                },
                {
                    title: 'Email',
                    dataIndex: 'order_detail.CustomerInfo.Address.EmailAddress',
                    key: 'order_detail.CustomerInfo.Address.EmailAddress',
                    sorter: (a, b) => a.order_detail.CustomerInfo.Address.EmailAddress.localeCompare(b.order_detail.CustomerInfo.Address.EmailAddress),
                },
                {
                    title: 'Item Count',
                    dataIndex: 'missing_items_count',
                    key: 'missing_items_count',
                    sorter: (a, b) => a.missing_items_count - b.missing_items_count,
                },
                {
                    title: 'Date',
                    dataIndex: 'order_detail.GeneralInfo.ReceivedDate',
                    key: 'order_detail.GeneralInfo.ReceivedDate',
                    scopedSlots: {
                        customRender: 'date'
                    },
                    sorter: (a, b) => new moment(a.order_detail.GeneralInfo.ReceivedDate).format('YYYYMMDD') - new moment(b.order_detail.GeneralInfo.ReceivedDate).format('YYYYMMDD'),
                },
                {
                    title: 'Priority',
                    dataIndex: 'priority',
                    key: 'priority',
                    scopedSlots: {
                        customRender: 'priority'
                    }
                },
                {
                    title: 'Queue Status',
                    dataIndex: 'q_status',
                    key: 'q_status',
                    sorter: (a, b) => a.q_status.localeCompare(b.q_status),
                },
                {
                    title: 'Action',
                    dataIndex: '',
                    key: 'x',
                    width: '230px',
                    scopedSlots: {
                        customRender: 'action'
                    }
                },
            ],
            orders: [],
            allOrders: [],
            detail: null,
            emailTemplate: "",
            fabEmailTemplate: "",
            clutchstopEmailTemplate: "",
            emailType: "",
            emailModalVisible: false,
            activeKey: null,
            newItemInput: '',
            modalData: {
                emailPreview: null,
                data: null,
                loading: false,
            },
            tomorrow: null,
            defDate: now,
            activeKey2: "Unfulfillable"
        }
    },
    async mounted() {
        this.tomorrow = moment().add(1, 'day').format('DD-MMM-YYYY')
        if (location.hostname === "localhost") {
            this.apiUrl = "https://darkside_api.test"
        }
        this.GetEmailTemplate();
        // this.GetData();
        // this.GetDataAll()
        await this.GetOrderData();
        this.isLoading = false;
        // this.CheckAllOrderQueues()
    },
    methods: {
        GetOrderData() {
            return new Promise((resolve) => {
                var isDone = 0;
                this.GetData().then(() => {
                    isDone++
                });
                this.GetDataAll().then(() => {
                    isDone++
                });

                var intvl = setInterval(() => {
                    if(isDone >= 2) {
                        clearInterval(intvl)
                        resolve();
                    }
                }, 500)
            });
        },
        GetData() {
            return new Promise((resolve) => {
                this.isLoading = true;
                this.$http.get(this.apiUrl + '/api/dispatch/orders/unfulfillable').then((resp) => {
                    resp.data.forEach(element => {
                        element.q_status = '';
                        element.missing_items_count = element.missing_items.length;
                        if (element.order_detail.CustomerInfo.ChannelBuyerName == null && element.order_detail.CustomerInfo.Address.EmailAddress == null) {
                            element.order_detail.CustomerInfo.ChannelBuyerName = "FABRICATION"
                        }
                        if (element.customer_name === "") {
                            element.customer_name = "Fabrication";
                        }
                        element.missing_items.forEach(el => {
                            el.type = "Missing";
                            el.include = true;
                            el.ShowCalendar = false;
                            if (el.BCTitle !== null) {
                                el.ItemTitle = el.BCTitle
                            }
                        });

                        if (element.priority === 1) {
                            element.priority = true;
                        }

                        if (element.priority === 0) {
                            element.priority = false;
                        }
                        if (element.email_sent === 0) {
                            element.email_was_sent = "No";
                        } else {
                            element.email_was_sent = "Yes";
                        }

                        element.order_detail.Items.forEach(el => {
                            //el.ItemTitle = el.BCTitle || el.Title
                            if (el.BCTitle !== null) {
                                el.ItemTitle = el.BCTitle
                            }
                            el.include = false;
                            el.LeadTime = 0;
                            el.ShowCalendar = false;
                            el.type = "Parent";
                            var find = element.missing_items.find(el2 => el2.SKU === el.SKU);
                            if (find === undefined) {
                                if (el.SKU !== "" && el.SKU !== null) {
                                    element.missing_items.push(el);
                                }
                            }

                            el.CompositeSubItems.forEach(element2 => {
                                var find2 = element.missing_items.find(el2 => el2.SKU === element2.SKU);
                                if (find2 === undefined) {
                                    if (element2.SKU !== "" && element2.SKU !== null) {
                                        if (element2.BCTitle !== null) {
                                            element2.ItemTitle = element2.BCTitle
                                        }
                                        //element2.ItemTitle = element2.BCTitle || element2.Title
                                        element2.include = false;
                                        element2.LeadTime = 0;
                                        element2.ShowCalendar = false;
                                        element2.type = "Composite";
                                        element.missing_items.push(element2);
                                    }
                                }
                            });
                        });
                    });
                    this.orders = resp.data
                    /*this.CheckAllOrderQueues();
                setInterval(() => {
                    this.CheckAllOrderQueues();
                }, 120000);*/
                }).finally(() => {
                    resolve();
                })
            });
        },
        GetDataAll() {
            return new Promise((resolve) => {
                this.isLoading = true;
                this.$http.get(this.apiUrl + '/api/dispatch/orders/all').then((resp) => {
                    resp.data.forEach(element => {
                        element.q_status = '';
                        element.missing_items_count = 0;
                        if ((element.CustomerInfo.ChannelBuyerName == null || element.CustomerInfo.ChannelBuyerName == "") && (element.CustomerInfo.Address.EmailAddress == null || element.CustomerInfo.Address.EmailAddress == "")) {
                            element.CustomerInfo.ChannelBuyerName = "FABRICATION"
                        }
                        if (element.customer_name === "") {
                            element.customer_name = "Fabrication";
                        }
                        element.Items.forEach(el => {
                            if (el.BCTitle !== null) {
                                el.ItemTitle = el.BCTitle
                            }
                        });

                        if (element.priority === 1) {
                            element.priority = true;
                        }

                        if (element.priority === 0) {
                            element.priority = false;
                        }
                        if (element.email_sent === 0 || !element.email_sent) {
                            element.email_was_sent = "No";
                        } else {
                            element.email_was_sent = "Yes";
                        }
                        element.item_count = element.Items.length;

                        element.Items.forEach(el => {
                            //el.ItemTitle = el.BCTitle || el.Title
                            if (el.BCTitle !== null) {
                                el.ItemTitle = el.BCTitle
                            }
                            el.include = false;
                            el.LeadTime = 0;
                            el.ShowCalendar = false;
                            el.type = "Parent";
                            var find = element.Items.find(el2 => el2.SKU === el.SKU);
                            if (find === undefined) {
                                if (el.SKU !== "" && el.SKU !== null) {
                                    element.Items.push(el);
                                }
                            }

                            el.CompositeSubItems.forEach(element2 => {
                                var find2 = element.Items.find(el2 => el2.SKU === element2.SKU);
                                if (find2 === undefined) {
                                    if (element2.SKU !== "" && element2.SKU !== null) {
                                        if (element2.BCTitle !== null) {
                                            element2.ItemTitle = element2.BCTitle
                                        }
                                        //element2.ItemTitle = element2.BCTitle || element2.Title
                                        element2.include = false;
                                        element2.LeadTime = 0;
                                        element2.ShowCalendar = false;
                                        element2.type = "Composite";
                                        element.Items.push(element2);
                                    }
                                }
                            });
                        });
                    });
                    this.allOrders = resp.data
                }).finally(() => {
                    resolve();
                })
            });
        },
        UpdatePriority(record, e) {
            record.priority = e.target.checked;
            this.$forceUpdate();
            this.$http.post(this.apiUrl + '/api/dispatch/orders/unfulfillable/priority', {
                id: record.id,
                priority: record.priority
            }).then(() => {});

        },
        UpdateContactedOtherMethod(record,e) {
            record.contacted_other_method = e.target.checked
            this.$forceUpdate();
            this.$http.post(this.apiUrl + '/api/dispatch/orders/unfulfillable/contacted-other-method', {
                id: record.id,
                contacted_other_method: record.contacted_other_method
            }).then(() => {});
        },
        GetEmailTemplate() {
            this.$http.get('EmailTemplate.html').then((resp) => {
                this.emailTemplate = resp.data
            });
            this.$http.get('FabEmailTemplate.html').then((resp) => {
                this.fabEmailTemplate = resp.data
            });
            this.$http.get('ClutchstopEmailTemplate.html').then((resp) => {
                this.clutchstopEmailTemplate = resp.data
            });
        },
        OnClickOutside(e, data) {
            if(e.target.localName !== 'button' && data.ShowCalendar) {
                data.ShowCalendar = false;
            }
        },
        UpdateLeadTime(val, data) {
            //console.log(val.format('DD-MMM-YYYY'), data);
            const selDate = moment(val.format('DD-MMM-YYYY'), 'DD-MMM-YYYY');
            const today = moment();
            const diff = selDate.diff(today, 'days') + 1;
            console.log(diff)
            if(diff === 1) {
                data.LeadTime = "Tomorrow"
            } else {
                data.LeadTime = val.format('DD-MMM-YYYY');
            }
            data.ShowCalendar = false;
            this.UpdateEmailPreview(null)
        },
        ShowDetails(order) {
            this.detail = order;
        },
        GetClass(e) {
            if (e.priority === 1) {
                return "priority";
            }
            return ""
        },
        UpdateEmailPreview(e) {
            if(e) {
                e.stopPropagation();
            }
            this.PreviewEmail(this.modalData.data, this.emailType)
            /*if (this.emailType === "darkside") {
                this.PreviewEmail(this.modalData.data)
            } else if (this.emailType === "fabrication") {
                this.PreviewFabEmail(this.modalData.data)
            } else if (this.emailType === "clutchstop") {
                this.PreviewClutchstopEmail(this.modalData.data)
            }*/
        },
        updateItemTitle(item) {
            this.$http.post(this.apiUrl + '/api/dispatch/orders/unfulfillable/title/update', {
                sku: item.SKU,
                title: item.ItemTitle
            }).then((resp) => {
                console.log(resp.data)
            }).catch(err => {
                console.log(err)
            })

            this.UpdateEmailPreview();
        },
        PreviewEmail(record, type) {
            let templateType = this.emailTemplate
            if(type === "fabrication") {
                templateType = this.fabEmailTemplate
            } else if (type === "clutchstop") {
                templateType = this.clutchstopEmailTemplate;
            } else {
                templateType = this.emailTemplate
            }
            var tempFn = doT.template(templateType);
            let SecondaryReference;
            let ChannelBuyerName;
            let missing_items;
            let missing_items_length;
            let Items;
            if(record.order_detail) {
                SecondaryReference = record.order_detail.GeneralInfo.SecondaryReference;
                ChannelBuyerName = record.order_detail.CustomerInfo.ChannelBuyerName;
                missing_items = record.missing_items;
                Items = record.order_detail.Items;
            } else {
                SecondaryReference = record.GeneralInfo.SecondaryReference;
                ChannelBuyerName = record.CustomerInfo.ChannelBuyerName;
                missing_items = [];
                Items = record.Items;
                missing_items = record.Items
                record.missing_items = record.Items
            }
            missing_items_length = missing_items.filter(el => el.type === "Missing" && el.include === true).length;
            const settings = {
                orderID: SecondaryReference,
                name: ChannelBuyerName,
                missing_items: missing_items,
                missing_items_count: missing_items_length,
                all_items: Items,
                tomorrow: this.tomorrow
            }
            if(!record.order_id) {
                record.order_id = record.OrderId
            }

            var resultText = tempFn(settings);
            this.modalData.emailPreview = resultText;
            this.modalData.data = record;
            this.emailModalVisible = true;
            this.emailType = type//"darkside";
        },
        PreviewFabEmail(record) {
            var tempFn = doT.template(this.fabEmailTemplate);
            var resultText = tempFn({
                orderID: record.order_detail.GeneralInfo.SecondaryReference,
                name: record.order_detail.CustomerInfo.ChannelBuyerName,
                missing_items: record.missing_items,
                all_items: record.order_detail.Items,
                tomorrow: this.tomorrow
            });
            this.modalData.emailPreview = resultText;
            this.modalData.data = record;
            this.emailModalVisible = true;
            this.emailType = "fabrication";
        },
        PreviewClutchstopEmail(record) {
            var tempFn = doT.template(this.clutchstopEmailTemplate);
            var resultText = tempFn({
                orderID: record.order_detail.GeneralInfo.SecondaryReference,
                name: record.order_detail.CustomerInfo.ChannelBuyerName,
                missing_items: record.missing_items,
                all_items: record.order_detail.Items,
                tomorrow: this.tomorrow
            });
            this.modalData.emailPreview = resultText;
            this.modalData.data = record;
            this.emailModalVisible = true;
            this.emailType = "clutchstop";
        },
        ResetModal() {
            this.modalData.emailPreview = null;
            this.modalData.data = null;
            this.emailType = "";
            this.selectedTypes = 'missing';
            this.newItemInput = '';
        },
        AddToKue(record, e) {
            e.target.textContent = "Added to Queue";
            var id = record.order_id;
            this.$http.post(this.apiUrl + '/api/dispatch/orders/unfulfillable/queue/add', {
                id: id,
                order: record
            }).then((resp) => {
                if (resp.data.success) {
                    this.$notification.success({
                        message: 'Success',
                        description: 'Added to queue',
                    });
                    this.GetData();
                } else {
                    alert('Failed to add to queue!');
                }
            })
        },
        SendEmail() {
            console.log(this.apiUrl + '/api/dispatch/orders/unfulfillable/email/send')
            var conf = confirm('Send email to customer?');
            if (conf) {
                this.modalData.loading = true;
                this.$http.post(this.apiUrl + '/api/dispatch/orders/unfulfillable/email/send', {
                    data: this.modalData.data,
                    html: this.modalData.emailPreview
                }).then((resp) => {
                    if (resp.data.success) {
                        var order_id = this.modalData.data.order_id;
                        if(this.modalData && this.modalData.data.order_detail) {
                            const findIndex = this.orders.findIndex(el => el.order_id === order_id);
                            if (findIndex >= 0) {
                                this.orders[findIndex].email_sent = 1;
                            }
                        } else {
                            const findIndex = this.allOrders.findIndex(el => el.order_id === order_id);
                            if (findIndex >= 0) {
                                this.allOrders[findIndex].email_sent = 1;
                            }
                        }

                        this.$notification.success({
                            message: 'Success',
                            description: 'Email Successfully Sent',
                        });
                        this.emailModalVisible = false;
                        this.modalData.loading = false;
                        this.modalData.data = null;
                        this.modalData.emailPreview = null;
                    } else {
                        this.modalData.loading = false;
                        alert('Failed to send email!');
                    }
                }).catch(() => {
                    this.modalData.loading = false;
                    alert('Failed to send email!');
                });
            }
        },
        GetQueueStatus(id) {
            return new Promise((resolve, reject) => {
                if(id !== undefined) {
                    this.$http.get('https://plp.darksidedev.net/jobs/search?q=' + id).then((resp) => {
                        resolve(resp.data)
                    }).catch(() => {
                        reject()
                    })
                } else {
                    reject();
                }
            });
        },
        async CheckAllOrderQueues() {
            for (let i = 0; i < this.allOrders.length; i++) {
                const order = this.allOrders[i];
                this.GetQueueStatus(order.OrderId).then((res) => {
                    const user = res.data.user;
                    let state = res.state;
                    if (state === "active") {
                        state = "IN OAP"
                    }
                    if (state === "inactive") {
                        state = "QUEUED"
                    }
                    if (state === "complete") {
                        state = "COMPLETED"
                    }
                    if (user !== 0) {
                        order.q_status = state + " - " + user;
                    } else {
                        order.q_status = state
                    }
                }).catch(() => {});
                await this.sleep(500)
            }

            for (let i = 0; i < this.orders.length; i++) {
                const order = this.orders[i];
                this.GetQueueStatus(order.order_id).then((res) => {
                    const user = res.data.user;
                    let state = res.state;
                    if (state === "active") {
                        state = "IN OAP"
                    }
                    if (state === "inactive") {
                        state = "QUEUED"
                    }
                    if (state === "complete") {
                        state = "COMPLETED"
                        //this.CompleteOrder(order.order_id)
                    }
                    if (user !== 0) {
                        order.q_status = state + " - " + user;
                    } else {
                        order.q_status = state
                    }
                }).catch(() => {});
                await this.sleep(500)
            }

            /*setInterval(() => {
                this.CheckAllOrderQueues();
            }, 120000);*/
        },
        CompleteOrder(id) {
            this.$http.post(this.apiUrl + '/api/dispatch/orders/unfulfillable/complete', {
                id: id
            }).then(() => {})
        },
        DeleteOrder(id) {
            var conf = confirm("Are you sure you want to delete?");
            if (conf) {
                this.$http.post(this.apiUrl + '/api/dispatch/orders/unfulfillable/delete', {
                    id: id
                }).then(() => {
                    var find = this.orders.findIndex(el => el.order_id === id);
                    if (find >= 0) {
                        this.orders.splice(find, 1);
                    }
                });
            }
        },
        AllItemsExcludingMissing(order) {
            var items = [];
            order.order_detail.Items.forEach(element => {
                var find = order.missing_items.find(el => el.ItemNumber === element.SKU)
                if (find == undefined) {
                    if (element.SKU !== "" && element.SKU !== null) {
                        element.ItemTitle = element.Title
                        items.push(element)
                    }
                }
            });
            return items
        },
        UpdateItemInclude(order, index, e) {
            console.log(e)
            console.log(this.AllItemsExcludingMissing(order)[index])
        },
        AddNewMissingItem(val) {
            this.modalData.data.missing_items.push({
                SKU: val,
                ItemTitle: "",
                LeadTime: 0,
                include: false,
                type: "Missing"
            })
            this.newItemInput = '';
        },
        ShouldShowItem(item) {
            if (item.type.toLowerCase() === this.selectedTypes || this.selectedTypes === 'all') {
                return true;
            } else {
                return false;
            }
        },
        contextmenus() {
            return [{
                text: 'Remove',
                action: e => {
                    console.log(e)
                }
            }]
        },
        sleep(milliseconds) {
            return new Promise(resolve => setTimeout(resolve, milliseconds))
        }
    },
    filters: {
        date(val) {
            return moment(val).format('DD-MM-YYYY HH:mm:ss')
        }
    },
    directives: {
        clickoutside
    }
});
</script>

<style>
.ant-collapse-content {
    overflow: inherit;
}
</style>
